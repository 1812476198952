import { BriefcaseIcon } from '@heroicons/react/outline'
import * as React from 'react'

export function AboutCard({ aboutCard }) {
  return (
    <div className="bg-white rounded-lg py-6 px-6">
      <div className="flex">
        <BriefcaseIcon className="w-6" />
        <p className="font-medium ml-4">{aboutCard.title}</p>
      </div>
      <p className="font-light pt-3">{aboutCard.content}</p>
    </div>
  )
}
