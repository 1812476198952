import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container } from '../components/container'
import Layout from '../components/layout'
import { SectionTitle } from '../components/section-title'
import { AboutCard } from '../components/about/about-card'
import { MemberCard } from '../components/about/member-card'
import { CTA } from '../components/cta'

const AboutPage = ({ data: { links, aboutPage } }) => {
  const updatedLinks = links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )
  const healthInfo = aboutPage.health[0]
  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={aboutPage.seoMetaTags}>
      {/* Our Labs */}
      <Container color="bg-beige">
        <SectionTitle title={aboutPage.title} main={true} centered={true} />
        <div className="space-y-8">
          <div className="grid grid-cols-1 sm:gap-10 sm:grid-cols-2">
            {aboutPage.ourLabsCard.map((labsCard, index) => (
                <AboutCard aboutCard={labsCard} />
            ))}
          </div>
        </div>
      </Container>
      {/* Health Info */}
      {healthInfo && (
        <Container color="bg-beige">
          <SectionTitle title={healthInfo.title} centered={true} />
          <GatsbyImage
            image={healthInfo.image.gatsbyImageData}
            alt={healthInfo.title}
            className="rounded-t-lg hidden sm:block"
          />
          <div className="bg-white rounded-xl py-12 -mt-2 z-10 px-8 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div
              className="font-light text-left prose"
              dangerouslySetInnerHTML={{
                __html: healthInfo.contentLeftNode.childMarkdownRemark.html,
              }}
            ></div>
            <div
              className="font-light text-left prose"
              dangerouslySetInnerHTML={{
                __html: healthInfo.contentRightNode.childMarkdownRemark.html,
              }}
            ></div>
          </div>
        </Container>
      )}
      <CTA />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query ($locale: String!) {
    aboutPage: datoCmsAboutPage(locale: { eq: $locale }) {
      title
      ourLabsCard {
        title
        content
      }
      teamTitle
      teamBlock {
        avatar {
          gatsbyImageData
        }
        role
        name
        bio
      }
      health {
        image {
          gatsbyImageData
        }
        title
        contentLeftNode {
          childMarkdownRemark {
            html
          }
        }
        contentRightNode {
          childMarkdownRemark {
            html
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    links: allDatoCmsAboutPage {
      nodes {
        slug
        locale
      }
    }
  }
`
