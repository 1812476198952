import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

export function CTA() {
  const { t } = useTranslation()
  return (
    <div className="bg-beige">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl md:text-5xl font-serif font-light tracking-tight">
          {t('cta-title')}
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 ">
          <div className="inline-flex bg-abel-green rounded-full  text-center">
            <Link
              to={`${t('link-base') + t('our-tests-url')}`}
              className="inline-flex items-center bg-abel-green justify-center px-16 py-3 text-lg rounded-full text-white hover:bg-abel-green-darker"
            >
              {t('cta-button')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
